<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>会员标签</el-breadcrumb-item>
            <el-breadcrumb-item>个人标签</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <!-- <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>群组类型：</label>
                  <el-select v-model="groupType"  class="width-220" placeholder="请选择">
                     <el-option v-for="(item,index) in dictData['member-group-type']"
                              :key="index"
                              :label="item"
                              :value="index">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)" v-text="$t('msg.search')">搜索</el-button>
               <el-button  icon="el-icon-refresh-right" plain @click="handleQuery(false)" v-text="$t('msg.reset')">重置</el-button>
            </el-row>
         </el-row>-->
         <!-- 主体内容 -->
         <el-row class="table-box">
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true"
                  border>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="nickName" label="会员姓名" min-width="50"></el-table-column>
               <el-table-column prop="mobile" label="手机号" min-width="70"></el-table-column>
               <el-table-column prop="certificateNo" label="身份证号" min-width="70"></el-table-column>
               <el-table-column prop="wecharId" label="微信号" min-width="70"></el-table-column>
               <el-table-column prop="province" label="籍贯" min-width="40"></el-table-column>
               <el-table-column label="会员等级" min-width="50">
                  <template slot-scope="scope">
                     {{ scope.row.level | filterLevel }}
                  </template>
               </el-table-column>
               <el-table-column label="来源" min-width="70">
                  <template slot-scope="scope">
                     {{ scope.row.source | filterSource }}
                  </template>
               </el-table-column>
               <el-table-column prop="industry" label="行业" min-width="50"></el-table-column>
               <el-table-column label="标签内容" min-width="160">
                  <template slot-scope="scope">
                     <el-tag
                           :class="['m-right-10', 'm-bottom-5', tagIds.includes(item.id) ? 'active' : '']"
                           v-for="item in scope.row.memberTagDTOS"
                           :key="item.id"
                           closable
                           @close="handleUnbind(item.id)"
                           :color="item.bgcolor"
                           effect="dark">
                        <i class="el-icon-check"></i>
                        {{ item.tagName }}
                     </el-tag>
                  </template>
               </el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button type="text" @click="handleSign(scope.row)" >
                        <el-link type="primary">标记</el-link>
                     </el-button>
                     <!--<el-button type="text" icon="el-icon-warning-outline" @click="handleDetail(scope.row)" >
                        <el-link type="primary">会员详情</el-link>
                     </el-button>-->
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </el-row>
      <!-- 新增标签 -->
      <el-form class="tag-pop">
         <el-dialog title="新增标签" :visible.sync="showLabel" width="400px">
            <el-row style="margin-top: 10%">
               <el-tag
                     :class="['m-right-10', tagIds.includes(item.id) ? 'active' : '']"
                     class="m-bottom-5"
                     v-for="item in tagData"
                     :key="item.id"
                     :color="item.bgcolor"
                     @click="changeCurrTag(item.id)"
                     effect="dark">
                  <i class="el-icon-check"></i>
                  {{ item.tagName }}
               </el-tag>
            </el-row>
            <div slot="footer" class="dialog-footer">
               <el-button @click="showLabel = false; tagIds.length = 0" size="mini">取 消</el-button>
               <el-button type="primary" @click="handleSave" size="mini">确 定</el-button>
            </div>
         </el-dialog>
      </el-form>
   </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { label} from '@/api/interface/data'
import { getDict } from "@/common/js/common"
export default {
   data() {
      return {
         groupType: '',
         hotelList: [],
         tableData: [],
         tagData: [],
         hotelId: '',
         loading: true,
         showLabel: false,
         userId: '',       // 会员ID
         total: 0,
         limit: 1,
         page: 1,
         tagIds: [],
         binding_success: '绑定成功!',
         update_success: '修改成功！',
         unBind_success: '解绑成功！',
         del_success: '删除成功！',
         confirm_remove: "此操作将解绑该标签, 是否继续?",
         prompt: "标题",
      }
   },
   computed: {
      ...mapState(['dictData', 'hotelInfo'])
   },
   mounted() {
      getDict(['member-group-type'])
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getTagLibrary()
      this.getMemberTag()
   },
   methods: {
      // 获取会员列表
      getMemberTag() {
         const url = label.membersTags + `?limit=${this.limit}&page=${this.page}`
         const param = {companyId:this.hotelInfo.storeId}
         this.$axios.post( url , param , 'json').then((res) => {
            if (res.success) {
               this.loading = false
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 添加标签
      handleSign(row) {
         this.userId = row.id
         this.showLabel = true
      },
      // 获取标签列表
      getTagLibrary() {
         const url = label.tagLibrary
         const param = { companyId:this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.loading = false
               this.tagData = res.records
            }
         })
      },
      // 改变当前标签
      changeCurrTag(id) {
         if (this.tagIds.includes(id)) this.tagIds = this.tagIds.filter(item => item !== id)
         else this.tagIds.push(id)
      },
      // 保存(绑定)标签
      handleSave() {
         const url = label.memberBindTags
         const param = {
            companyId:this.hotelInfo.storeId,
            hotelId: this.hotelInfo.id,
            userId: this.userId,
            tagIds: this.tagIds.toString()
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.$message({
                  showClose: true,
                  message: this.binding_success,
                  type: 'success'
               })
               this.tagIds.length = 0
               this.showLabel = false
               this.getMemberTag()
            }
         })
      },
      // 删除(解绑)标签
      handleUnbind(id) {
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            const param = { hotelId: this.hotelInfo.id, id }
            const url = label.memberUnbindTag
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.unBind_success,
                     type: 'success'
                  })
                  this.getMemberTag()
               }
            })
         })
      },
      // 改变每页数
      changePagNum(num) {
         this.limit = num
         this.getMemberTag()
      },
      // 改变当前页
      changeCurrPage(num) {
         this.page = num
         this.getMemberTag()
      },
      // 查询个人标签
      handleQuery(bool) {
         this.page = 1
         if (bool) return this.getMemberTag()
         this.groupType = ''
      },
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         oldVal && newVal.id !== oldVal.id && this.getTagLibrary(); this.getMemberTag()
      },
   },
   filters: {
      filterLevel(val) {
         switch (val) {
            case 'USER':
               return val = '普通用户'
            case 'COMMON':
               return val = '普通会员'
            case 'ONE':
               return val = '一级会员'
            case 'TWO':
               return val = '二级会员'
            case 'THREE':
               return val = '三级会员'
         }
      },
      filterSource(val) {
         switch (val) {
            case 'APPLETS':
               return val = '小程序'
            case 'SSM':
               return val = '自助机'
            case 'APP':
               return val = 'APP'
            case 'WEB':
               return val = 'PC端'
            case 'PLATFORM':
               return val = '平台'
            case 'SMS':
               return val = '短信'
            case 'EMAIL':
               return val = '邮件'
            case 'WECHAT_OFFICIAL':
               return val = '微信公众号'
            case 'WECHAT_APPLET':
               return val = '微信小程序'
            case 'QQ':
               return val = 'QQ'
            case 'ALIPAY':
               return val = '支付宝'
            case 'DINGTALK':
               return val = '钉钉'
            case 'DOUYIN':
               return val = '抖音'
            case 'XIAOHONGSHU':
               return val = '小红书'
            case 'PMS':
               return val = '酒店PMS'
         }
      }
   }
}
</script>

<style scoped lang="scss">
   /deep/ .el-tag--dark{
      border-color: transparent;
   }
   .tag-pop .active .el-icon-check{
      display: inline;
   }
   .el-icon-check{
      display: none;
   }
</style>
